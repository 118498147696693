@font-face {
	font-family: "Open Sans";
	src: url(../public/fonts/OpenSans-VariableFont_width.ttf);
}

*,
*::before,
*::after {
	box-sizing: border-box;
	font-family: "Open Sans";
	letter-spacing: 0.15px;
}

.navbar {
	box-shadow: 0px 0px 20px 20px #3c40430f;

	& img {
		margin-right: 2vw;
		margin-left: 2vw;
		height: 90px;
		width: 90px;
	}

	& h1 {
		font-weight: 400;
		line-height: 52px;
		color: #4e59e3;
	}
}

.breadbar {
	background-color: #d7dbf2;
	padding-top: 2vh;
	padding-bottom: 2vh;

	& svg {
		margin-left: 5vw;
	}

	& h1 {
		margin-bottom: 0;
		margin-left: -40px;
		margin-right: -40px;
	}
}

.mobile-input {
	border-radius: 10px;
	font-size: 10rem;
	box-shadow: 0px 0.5rem 1rem rgb(0 0 0 / 15%);

	& input {
		border-radius: 10px;
		background-color: rgba(197, 200, 237, 0.37);

		&:active {
			background-color: blueviolet;
		}

		&:focus {
			background-color: rgba(197, 200, 237, 0.37);
			box-shadow: 0px;
		}

		&:valid {
			box-shadow: none;
		}
	}
}

.submit-button {
	padding-inline: 3rem;
	padding-block: 1rem;
	border-radius: 52px;
	background: rgba(94, 104, 231, 1);
}

.otp-input-box {
	border-radius: 4px;
	border: 2px solid rgba(94, 104, 231, 1);
	text-align: center;
	font-size: 4rem;
	font-weight: lighter;
	margin-right: 0.5rem;
	background-color: rgba(234, 235, 248, 1);
}

.home-page {
	padding-top: 20px;
}

.calendarWrap-text {
	font-weight: 400;
	line-height: 52px;
	text-align: left;
	color: #7b7b7b;
}

.calendarWrap {
	display: inline-block;
	position: relative;

	.icon {
		color: white;
		background-color: #5e68e7;
	}

	.dateinputBox {
		font-size: 22px;
		padding: 5px 8px 4px 8px;
		border-radius: 3px;

		& :hover {
			background-color: #e9e9e9;
		}
	}

	.rdrDefinedRangesWrapper {
		background-color: rgba(234, 235, 248);
		width: 10rem;
	}

	.rdrDayNumber {
		& span {
			color: #000;
		}
	}
}

.calendarElement {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 40px;
	border: 1px solid #ccc;
	z-index: 999;
}

.uploaded-files-card {
	width: 60%;
	border: 0.5px solid #adadad;
	border-radius: 20px;
	flex-direction: row;
	padding: 1rem;

	.card-img {
		object-fit: none;
		width: 100px;
	}

	.card-body {
		.card-title {
			color: #5f6368;
			font-weight: 600;
		}
		.card-subtitle {
			color: #c6c5c5;
			font-weight: 400;
		}
	}
}

.upload-box-text {
	font-style: normal;
	font-weight: 600;
	line-height: 52px;
	text-align: left;
	color: #5f6368;
}

.upload-box {
	background: rgba(234, 235, 248, 0.4);
	border: 3px dashed #4e59e3;
	border-radius: 15px;
	height: 40vh;

	border-radius: 15px;

	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;

	&:hover {
		background: rgba(234, 235, 248);
	}

	.btn {
		border-radius: 6px;
		background: linear-gradient(
			87deg,
			#7982ed 2.98%,
			rgba(25, 37, 198, 0.71) 97.58%
		);
		padding: 1rem 2rem;
	}

	.text-muted {
		color: #7b7b7b;
		font-weight: 400;
		font-size: 15px;
		color: #2d363d;
	}
}

.model-body {
	& h3 {
		font-size: 28px;
		font-weight: 700;
		line-height: 37px;
	}

	& h4 {
		font-size: 22px;
		font-weight: 600;
		line-height: 37px;
	}
}

.modal-body {
	.btn-close {
		display: flex;
		position: absolute;
		right: 2rem;
	}
}

.loading-screen {
	color: rgba(94, 104, 231, 1);

	& span {
		margin-top: 1rem;
		width: 60px;
		height: 60px;
	}

	.progress {
		margin-top: 1rem;
		background-color: #cfd2ef;
		border-radius: 15px;
		height: 30px;

		.progress-bar {
			background-color: #5e68e7;
		}
	}

	.modal-body {
		.btn-next {
			display: flex;
			position: absolute;
			right: 1rem;
			color: rgba(73, 84, 100, 1);
			background: none;
			border: none;
			size: 10;
		}

		& span {
			color: #208114;
		}

		& h1 {
			font-size: 40px;
			font-weight: 700;
		}

		& h3 {
			font-size: 18px;
			font-weight: 400;
			margin: 0px;
		}

		& h4 {
			color: rgba(180, 41, 41, 1);
			font-size: 14px;
			font-weight: 400;
		}

		& p {
			font-size: 20px;
			margin-bottom: 0px;
			font-weight: 400;
			line-height: 27px;
			color: rgba(73, 84, 100, 1);
		}

		.muted {
			margin: 0;
			color: #adadad;
			font-weight: 400;
			font-size: 15px;
		}

		.btn-cancel {
			margin-top: 1rem;
			border-radius: 32px;
			background: #b42929;
		}
	}

	.file-name-card {
		border: 2px solid #eff0f8;
		border-radius: 10px;
		margin-bottom: 1rem;
		padding-block: 10px;

		.file-name-card-content {
			display: inline;
			align-items: center;
			text-align: left;
			color: rgba(73, 84, 100, 1);
			font-weight: 400;
			font-size: 24px;

			h4 {
				font-weight: 400;
				font-size: 14px;
			}
		}

		.file-name-card-check {
			display: flex;
			align-items: center;
			text-align: right;
			justify-content: flex-end;
		}
	}
}

.result-page {
	width: 90vw;
	padding: 0;
	margin-right: 5vw;
	margin-left: 5vw;
}

.lung-image {
	& .img.no-tb {
		margin: 0;
		padding: 0;
		border: 5px solid rgba(60, 178, 114, 1);
	}

	& .img.tb {
		margin: 0;
		padding: 0;
		border: 5px solid rgba(237, 85, 59, 1);
	}

	& h4.no-tb {
		background-color: rgba(60, 178, 114, 1);
		color: white;
		font-size: 1rem;
		padding: 10px;
	}

	& h4.tb {
		background-color: rgba(237, 85, 59, 1);
		color: white;
		font-size: 1rem;
		padding: 10px;
	}
}

.patient-detail-card {
	background: #fbfbfb;
	margin-bottom: 25px;
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
	padding-bottom: 5rem;

	& p {
		margin-top: 1rem;
		margin-bottom: 0;
		color: rgba(91, 91, 91, 1);
		margin-left: 1vw;
	}

	& h5 {
		margin-left: 1vw;
	}

	& h2 {
		color: rgba(91, 91, 91, 1);
		padding-top: 1vh;
		padding-bottom: 1vh;
		margin-left: 1vw;
	}
}

.logout-button {
	position: absolute;
	right: 1rem;
	background-color: #7b7b7b;
	color: white;
}

.xray-result-card {
	box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
	height: 30vh;
}

.xray-result-card p {
	font-size: 1.1rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.xray-result-card .disclaimer {
	margin-bottom: -2rem;
}

.xray-result-card .disclaimer-read-more {
	color: white;
	margin: 0;
	padding-block: 0.5rem;
	padding-inline: 0;
}

.xray-result-card .disclaimer-read-more:hover {
	text-decoration: underline white;
}
